<template>
  <div class="home">
    <div class="header">
      <div class="left">
        <div class="texts">储蓄保平台</div>
      </div>
      <div class="right"></div>
    </div>
    <div class="title">运营商管理平台</div>
    <div class="neirong">
      <div class="yi" @click="see()">
        <div class="left">数据查看</div>
        <div class="right">
          <img src="../../assets/about/jingyingbaibiao.png" alt="" />
        </div>
      </div>
      <div class="yi" @click="team()">
        <div class="left">团队管理</div>
        <div class="right">
          <img src="../../assets/about/m.png" alt="" />
        </div>
      </div>
      <!-- <div class="yi" @click="teamdata()">
        <div class="left">团队数据</div>
        <div class="right">
          <img src="../../assets/about/tuanduishuju .png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  // name: 'Home',
  created() {},
  methods: {
    see() {
      this.$router.push({ path: "/aboutlist" });
    },
    team() {
      this.$router.push({ path: "/team" });
    },
    teamdata() {
      this.$router.push({ path: "/dusers" });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#1f7cff, #ffffff);
  display: flex;
  flex-direction: column;
  .header {
    width: 6.98rem;
    height: 1.07rem;
    display: flex;
    justify-content: space-between;
    .left {
      width: 1.8rem;
      height: 100%;
      display: flex;
      align-items: flex-end;
      margin-left: 0.09rem;
      .texts {
        font-size: 0.36rem;
        color: #ffffff;
        font-weight: bolder;
      }
    }
    .right {
      width: 1.46rem;
      height: 100%;
    }
  }
  .title {
    font-size: 0.36rem;
    color: #ffffff;
    margin: 0.43rem 0 0 0.64rem;
  }
  .neirong {
    width: 6.98rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0.5rem auto;
    .yi {
      width: 3.3rem;
      height: 1.18rem;
      background-color: #ffffff;
      margin-bottom: 0.26rem;
      border-radius: 0.08rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .left {
        color: #333333;
        font-size: 0.26rem;
      }
      .right {
        width: 0.92rem;
        height: 0.92rem;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}
</style>
