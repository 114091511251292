<template>
  <div class="channel">
    <div class="header">渠道商合作协议</div>
    <div class="neirong">
      本规则由北京天地博爱网络科技有限公司（以下亦可称“天地博爱”）与您（以下亦可称为“本人”）签署，为使用天地博爱平台（储蓄保平台）的服务（以下简称：本服务），本人自愿遵守《用户守则》（以下简称本规则）、《渠道商合作协议》（https://www.chuxubao.com/contract.shtml）等相关协议、规则。特别是相关权利的许可使用和免除或者限制责任的条款，以及开通或使用某项服务的单独协议、规则。本人点击“确认”本规则，即视为本人已阅读并同意上述协议、规则等的约束。本人有违反本规则的任何行为时，天地博爱有权依照违反情况，随时单方限制、中止或终止向本人提供本服务，并有权追究本人的相关违约责任。<br />

      本着诚实守信、合作共赢的原则，本着长远发展、资源共享、互惠互利的目标，经双方友好协商，现甲⼄双⽅根据《中华⼈民共和国公司法》、《中华⼈民共和国合同法》及相关法律法规的规定，经协商⼀致，双方达成达成如下协议：<br />
      第⼀条、渠道商合作方式和范围<br />
      1、乙方同意支付28888元成为甲方储蓄保渠道商，面向全国推广储蓄保平台，并遵守储蓄保平台的规则、制度和运营方式。<br />
      2、乙方获得权益如下： ①10套商户系统； ②10%的养老金，<br />
      ③金卡会员资格（价值999元）； ④推广商户的总流水万八的服务费；<br />
      ⑤推广商户，产生养老金总流水的5%的管理费；
      ⑥享有金卡会员所有权益（1年）；<br />
      ⑦享有商户系统的使用权（1年）；<br />
      ⑧招募代理商奖励：10%招募奖＋1%管理费（永久）；<br />
      ⑨招募渠道商招募奖20%＋培育奖15%； ⑩招募渠道商管理奖2%＋1%（永久）；<br />
      第⼆条、甲方权利与义务 1、甲方需要当天开通乙方的渠道商权益。<br />
      2、乙方开通商户资格需要保证提交资料的正确性，开通时间以审核通过时间为准。<br />
      3、甲方保证储蓄保系统正常运行，除了不可抗拒因素外，系统正常运行。<br />
      第三条、结算方式<br />
      甲方根据结算方式和周期，定期给予乙方进行结算，乙方获得权益需要根据甲方的结算方式和周期进行结算。<br />
      1、商户万八流水收益按季度结算，结算方式，公司打款。<br />
      2、商户流水分润收益按季度结算，结算方式，公司打款。<br />
      3、代理商管理费按季度结算，结算方式，公司打款。<br />
      4、渠道商管理奖按季度结算，结算方式，APP提现。<br />
      5、大仓会员分红按季度结算，结算方式，APP提现。<br />
      6、其他收益当天立即结算，结算方式，APP提现。
      四、协议变更、终止及违约责任<br />
      1、甲、乙双方应本着诚实信用的原则善意履行本协议，若发现一方的行为严重违反本协议，或严重违背商业道德和法律或损害对方利益，另一方可要求违约方停止或纠正该等行为，并赔偿由此给守约方带来的一切损失，包括（但不限于）受害方为维权而支付的律师费、鉴定费、保全费、诉讼费、公证费等费用。<br />
      2、订立本协议所依据的法律、行政法规、规章发生变化，本协议应变更相关内容;订立本协议所依据的客观情况发生重大变化致使本协议无法履行的，经甲乙两方协商，可以变更或者终止协议的履行。<br />
      3、如在实际合作中有协议内未作约定的情况发生，则甲乙双方应本着互惠互利的原则，<br />
      以有利于顺利完成合作项目的目标，实现预定的商业目的为原则，协商解决，并可另行签署补充协议，补充协议与本协议具有相同法律效力。
      4、由于不可抗力或协议一方无法预见、控制、避免和克服的事件导致不能或暂时不能全部或部分履行本协议，甲方不负责任。但是，受不可抗力事件影响的一方须尽快将发生的事件通知另一方，并采取一切必要措施降低损失的发生。<br />
      五、保密条款<br />
      1、未经对方书面同意，任何一方均不得向其他第三人泄漏在协议履行过程中知悉的商业秘密或相关信息，也不得将本协议内容及相关档案材料泄漏给任何第三方。但法律、法规规定必须披露的除外。<br />
      2、保密条款为独立条款，不论本协议是否签署、变更、解除或终止等，本条款均有效。<br />
      六、争议解决<br />
      在本协议执行期间如双方发生争议，双方应本着互谅互让的原则，友好协商处理。如协商不成，双方均可向甲方所在地人民法院提起诉讼。<br />
      七、免责声明<br />
      1、本人理解并同意：鉴于网络服务的特殊性，天地博爱有权在无需通知我的情况下根据整体运营情况或相关运营规范、规则等，可以随时变更、中止或终止部分或全部的服务，若由此给我造成损失的，我同意放弃追究天地博爱的相关责任。<br />
      2、本人理解并同意：为了向本人及其他用户提供更完善的服务，天地博爱有权定期或不定期地对提供本服务的平台或相关设备进行检修、维护、升级等，此类情况可能会造成相关服务在合理时间内中断或暂停的，若由此给本人造成损失的，本人同意放弃追究天地博爱的责任。<br />
      7、本人理解并同意：天地博爱的服务是按照现有技术和条件所能达到的现状提供的。天地博爱会尽最大努力向开发者提供服务，确保服务的连贯性和安全性；但天地博爱不能保证其所提供的服务毫无瑕疵，也无法随时<br />
      预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。所以本人同意：<br />
      即使天地博爱提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为天地博爱违约，同时，由此给本人造成的数据或信息丢失等损失的，本人同意放弃追究天地博爱的责任。<br />
    </div>
  </div>
  <!-- 毅 -->
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.channel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8 !important;
  .header {
    width: 100%;
    height: 1.26rem;
    background-color: #fff;
    color: #333333;
    font-size: 0.36rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    position: fixed;
    top: 0;
  }
  .neirong {
    width: 6.86rem;
    height: auto;
    background-color: #fff;
    margin-top: 1.52rem;
    border-radius: 0.16rem;
    color: #333333;
    font-size: 0.28rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
  }
}
</style>