<template>
  <div class="user">
    <div class="header">
      <img src="../../assets/user/suer.png" alt="" />
      <div class="top">
        <div class="left" @click="tuichu()">退出登录</div>
        <div class="right"></div>
      </div>
      <div class="bottom">
        <!-- <div class="l">
          <div class="tops">运营商</div>
          <div class="bots">身份</div>
        </div> -->
        <div class="in">
          <div class="icon">
            <img :src="userinfo.avatar" alt="" />
          </div>
          <div class="tests">
            <div class="nisjdf">{{ userinfo.name }}</div>
            <div class="sdfsddsg">{{ userinfo.mobile }}</div>
          </div>
        </div>
        <!-- <div class="r">
             <div class="tops">渠道商</div>
          <div class="bots">推广身份</div>
        </div> -->
      </div>
    </div>
    <div class="nihao">
      <div class="top">
        <img src="../../assets/user/erweima.png" alt="" />
      </div>
      <div class="bottom">专享客服微信</div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { Getinfo } from "../../api/user";
import { Getlogout } from "../../api/request";
export default {
  data() {
    return {
      userinfo: [],
      token: "",
    };
  },
  created() {
    // console.log(this.$route.path,999)
    this.token = localStorage.getItem("token");
    this.Getinfos();
  },
  methods: {
    tuichu() {
      Getlogout(this.token).then(
        (res) => {
          if (res.status == 200) {
            localStorage.removeItem("token");
            Message({
              message: res.data,
              type: "success",
              duration: 2000,
            });
            this.$router.push({ path: "/" });
          } else if (res.status != 200) {
            Message({
              message: res.data,
              type: "error",
              duration: 2000,
            });
          }
        },
        (fail) => {
          Message({
            message: fail.data,
            type: "error",
            duration: 2000,
          });
        }
      );
    },
    Getinfos() {
      Getinfo().then(
        (res) => {
          if (res.status == 200) {
            this.userinfo = res.data;
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.user {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 100%;
    height: 4.07rem;
    position: relative;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
    .top {
      position: absolute;
      width: 6.98rem;
      height: 0.54rem;
      top: 1.07rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        color: #fff;
        font-size: 0.36rem;
      }
    }
    .bottom {
      position: absolute;
      width: 6rem;
      height: 3.14rem;
      top: 2.4rem;
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      align-items: flex-start;
      // background-color: aqua;
      .l {
        height: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .tops {
          height: 0.32rem;
          font-size: 0.32rem;
          color: #ffffff;
          line-height: 0.32rem;
        }
        .bots {
          height: 0.26rem;
          font-size: 0.26rem;
          color: #ffffff;
          line-height: 0.26rem;
        }
      }
      .in {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 2.1rem;
          height: 2.1rem;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        .tests {
          height: 0.72rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .nisjdf {
            color: #333333;
            font-size: 0.32rem;
            line-height: 0.32rem;
          }
          .sdfsddsg {
            color: #333333;
            font-size: 0.24rem;
            line-height: 0.24rem;
          }
        }
      }
      .r {
        height: 0.74rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .tops {
          height: 0.32rem;
          font-size: 0.32rem;
          color: #ffffff;
          line-height: 0.32rem;
        }
        .bots {
          height: 0.26rem;
          font-size: 0.26rem;
          color: #ffffff;
          line-height: 0.26rem;
        }
      }
    }
  }
  .nihao {
    width: 2.85rem;
    height: 3.49rem;
    margin-top: 2.43rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .top {
      width: 100%;
      height: 2.85rem;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .bottom {
      color: #333333;
      font-size: 0.32rem;
    }
  }
}
</style>