import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// import axios from 'axios';
import 'element-ui/lib/theme-chalk/index.css';
import VueLoadmore from 'vuejs-loadmore';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueLoadmore);

// Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')