import { Service } from './axios'
// 新增成员
export function Postbindadd(data) {
    return Service({
        url: '/api/v1/operate/bind_add',
        method: 'post',
        data: data,
    })
}
// 团队列表
export function Gitbindlist(data) {
    return Service({
        url: '/api/v1/operate/bind_list',
        method: 'get',
        params: { page: data.page, limit: data.limit }
    })
}