import { Service } from './axios'
export function Postverify(data) {
  //发送验证码
  return Service({
    url: '/api/auth/verify',
    method: 'post',
    data: data,
  })
}
// 登录
export function Postlogin(data) {
  return Service({
    url: '/api/v1/operate/login',
    method: 'post',
    data: data,
  })
}
// 登出
export function Getlogout(data) {
  return Service({
    url: '/api/v1/operate/logout',
    method: 'get',
    params: { Authorization: data.token }
  })
}
