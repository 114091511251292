<template>
  <div class="teamadd">
    <div class="header">
      <div class="neizi">
        <div class="left">
          <div class="icon" @click="fanhui()">
            <img src="../../assets/signin/sinfh.png" alt="" />
          </div>
          <div class="texts">编辑姓名</div>
        </div>
        <div class="right"></div>
      </div>
    </div>
    <div class="neirong">
      <div class="yi">
        <input
          type="text"
          placeholder="请输入姓名"
          v-model="fromdata.name"
          maxlength="11"
          autofocus
          pattern="[0-9]*"
        />
      </div>
      <div class="yi">
        <input
          type="text"
          placeholder="请输入绑定手机号"
          v-model="fromdata.phone"
          maxlength="11"
          oninput="value=value.replace(/[^\d]/g,'')"
          autofocus
          pattern="[0-9]*"
        />
      </div>
      <div class="yi is">
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="fromdata.code"
          maxlength="4"
          oninput="value=value.replace(/[^\d]/g,'')"
          autofocus
          pattern="[0-9]*"
        />
        <div
          v-if="this.show == true"
          class="qnniuyan"
          :style="
            fromdata.phone.length == 11
              ? 'background-color:#1F7CFF'
              : 'background-color:#c8c8c8'
          "
        >
          <span v-show="show" @click="getCode">获取验证码</span>
          <span v-show="!show" class="count">({{ count }}s)</span>
        </div>
        <div
          v-else
          class="qnniuyan"
          :style="
            this.show == true
              ? 'background-color:#1F7CFF'
              : 'background-color:#c8c8c8'
          "
        >
          <span v-show="show" @click="getCode">获取验证码</span>
          <span v-show="!show" class="count">({{ count }}s)</span>
        </div>
      </div>
    </div>
    <div class="submit" @click="submit()">确定</div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { Postbindadd } from "../../api/teamlist";
import { Postverify } from "../../api/request";
export default {
  data() {
    return {
      show: true,
      count: "",
      timer: null,
      fromdata: {
        name: "",
        phone: "",
        code: "",
      },
    };
  },
  created() {},
  methods: {
    fanhui() {
      history.back();
    },
    getCode() {
      var mobileReg = /^1[3,4,5,7,8,9][0-9]{9}$/;
      if (this.fromdata.name == "") {
        Message({
          message: "请输入姓名",
          type: "error",
          duration: 2000,
        });
      } else if (this.fromdata.phone == "") {
        Message({
          message: "请输入手机号",
          type: "error",
          duration: 2000,
        });
      } else if (!mobileReg.test(this.fromdata.phone)) {
        Message({
          message: "请输入合格手机号",
          type: "error",
          duration: 2000,
        });
      } else {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          if (this.show == false) {
            Postverify(this.fromdata).then(
              (res) => {
                if (res.status == 200) {
                  Message({
                    message: res.message,
                    type: "success",
                    duration: 2000,
                  });
                  return;
                } else if (res.status != 200) {
                  Message({
                    message: res.message,
                    type: "error",
                    duration: 2000,
                  });
                }
              },
              (fail) => {
                console.log(fail);
              }
            );
          }
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              console.log(this.show);
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    submit() {
      var mobileReg = /^1[3,4,5,7,8,9][0-9]{9}$/;
      if (this.fromdata.name == "") {
        Message({
          message: "请输入姓名",
          type: "error",
          duration: 2000,
        });
      } else if (this.fromdata.phone == "") {
        Message({
          message: "请输入手机号",
          type: "error",
          duration: 2000,
        });
      } else if (!mobileReg.test(this.fromdata.phone)) {
        Message({
          message: "请输入合格手机号",
          type: "error",
          duration: 2000,
        });
      } else if (this.fromdata.code == "") {
        Message({
          message: "请输入验证码",
          type: "error",
          duration: 2000,
        });
      } else {
        Postbindadd(this.fromdata).then(
          (res) => {
            if (res.status == 400) {
              Message({
                message: res.message,
                type: "error",
                duration: 2000,
              });
            } else if (res.status != 400) {
              Message({
                message: res.message,
                type: "success",
                duration: 2000,
              });
              this.$router.push({ path: "/team" });
            }
          },
          (fail) => {
            console.log(fail);
          }
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.teamadd {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #f7f8fc !important;
  .header {
    width: 100%;
    background-color: #fff;
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .neizi {
      width: 6.98rem;
      height: 0.88rem;
      display: flex;
      justify-content: space-between;
      .left {
        width: 2.06rem;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 0.36rem;
          height: 0.36rem;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .texts {
          font-size: 0.36rem;
          color: #333333;
          font-weight: bolder;
        }
      }
      .right {
        width: 1.46rem;
        height: 100%;
      }
    }
  }
  .neirong {
    width: 100%;
    height: 3.16rem;
    margin: 0.26rem 0 1.8rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .yi {
      width: 6.86rem;
      height: 0.88rem;
      background-color: #fff;
      border-radius: 0.16rem;
      display: flex;
      align-items: center;
      input {
        width: 100%;
        height: 0.55rem;
        border: none;
        outline: none;
        margin: 0 0 0 0.32rem;
      }
    }
    .is {
      position: relative;
      .qnniuyan {
        position: absolute;
        bottom: 0.05rem;
        right: 0.01rem;
        width: 2rem;
        height: 0.8rem;
        // background-color: #c8c8c8;
        border-radius: 0.16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        color: #ffffff;
      }
    }
  }
  .submit {
    width: 6.86rem;
    height: 0.98rem;
    background-color: #1f7cff;
    border-radius: 0.08rem;
    opacity: 0.5;
    margin: 0 auto;
    text-align: center;
    line-height: 0.98rem;
    font-size: 0.36rem;
    color: #ffffff;
  }
}
input::v-deep button,
input,
textarea {
  font: caption;
}
</style>