import { Service } from './axios'
// 数据查看-统计
export function Getdatatotal(data) {
    if (data == null || data == "" || data == undefined) {
        return Service({
            url: '/api/v1/operate/data_total',
            method: 'get',
        })
    } else {
        return Service({
            url: '/api/v1/operate/data_total',
            method: 'get',
            params: { id: data.id }
        })
    }
}
// 数据查看-列表
export function Getdatalistl(data) {
    return Service({
        url: '/api/v1/operate/data_list',
        method: 'get',
        params: { page: data.page, limit: data.limit, type: data.type }
    })
}
// 团队详情-列表
export function Getdetaillis(data) {
    return Service({
        url: '/api/v1/operate/detail_list',
        method: 'get',
        params: { user_id: data.ids.id, user_type: data.user_type, page: data.page, limit: data.limit }
    })
}
// 用户类型-列表
export function Getusertypelist() {
    return Service({
        url: '/api/v1/operate/user_type/list',
        method: 'get',
    })
}