<template>
  <div class="nihao">
      <div class="header">
      <div class="neizi">
        <div class="left">
          <div class="icon" @click="fanhui()">
            <img src="../../assets/signin/sinfh.png" alt="" />
          </div>
          <div class="texts">{{Listdata.title}}</div>
        </div>
        <div class="right"></div>
      </div>
    </div>
    <div class="content" v-html="Listdata.content"></div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { Getdetail } from "../../api/agreement";
export default {
  data() {
    return {
      type: "",
      Listdata: [],
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.Getdetails();
  },
  methods: {
       fanhui() {
      history.back();
    },
    Getdetails() {
      Getdetail(this.type).then(
        (res) => {
          if (res.status == 200) {
            this.Listdata = res.data;
          } else {
            Message({
              message: "res.message",
              type: "error",
              duration: 2000,
            });
            return;
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.nihao {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
   .header {
    width: 100%;
    background-color: #fff;
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .neizi {
      width: 6.98rem;
      height: 0.88rem;
      display: flex;
      justify-content: space-between;
      .left {
        width: 2.06rem;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 0.36rem;
          height: 0.36rem;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .texts {
          font-size: 0.36rem;
          color: #333333;
          font-weight: bolder;
        }
      }
      .right {
        width: 1.46rem;
        height: 100%;
      }
    }
  }
  .content {
    margin: 0.45rem auto 0;
    font-size: 0.28rem;
    padding: 0 0.13rem;
  }
}
</style>