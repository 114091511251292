import { render, staticRenderFns } from "./Teamadd.vue?vue&type=template&id=531c6946&scoped=true&"
import script from "./Teamadd.vue?vue&type=script&lang=js&"
export * from "./Teamadd.vue?vue&type=script&lang=js&"
import style0 from "./Teamadd.vue?vue&type=style&index=0&id=531c6946&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531c6946",
  null
  
)

export default component.exports