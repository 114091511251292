<template>
  <div class="aboutlist">
    <!-- <div style="width: 100%; background-color: #fff; margin-left: 0.32rem"> -->
    <div class="header">
      <div class="neizi">
        <div class="left">
          <div class="icon" @click="fanhui()">
            <img src="../../assets/signin/sinfh.png" alt="" />
          </div>
          <div class="texts">数据查看</div>
        </div>
        <div class="right"></div>
      </div>
    </div>
    <!-- </div> -->
    <div class="user">
      <div class="in" v-for="(item, index) in muser" :key="index">
        <div class="top">{{ item.count }}</div>
        <div class="bottom">{{ item.name }}</div>
      </div>
    </div>
    <div class="tianshu">
      <div
        class="left"
        v-for="(item, index) in int"
        :key="index"
        @click="ints(index)"
        :style="intsindex == index ? 'color: #1f7cff;' : 'color:#999999'"
      >
        <div>按{{ item.name }}</div>
        <i class="el-icon-caret-bottom"></i>
      </div>
      <!-- <div class="left" style="margin: 0.32rem 0 0 0">
        <div>按月</div>
        <i class="el-icon-caret-bottom"></i>
      </div> -->
    </div>
    <div class="riqisi">
      <div class="int" v-for="(item, index) in yunpai" :key="index">
        {{ item.name }}
      </div>
    </div>
    <div class="huakou">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          offset="10"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="(item, index) in formdata" :key="index">
            <div class="yi">
              <div>{{ item.date }}</div>
              <div>{{ item.user_count }}</div>
              <div>{{ item.warehouse_count }}</div>
              <div>{{ item.merchant_count }}</div>
              <div>{{ item.channel_count }}</div>
              <div>{{ item.agent_count }}</div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
// import { Message } from "element-ui";
import { Getdatatotal, Getdatalistl } from "../../api/aboutlist";
export default {
  data() {
    return {
      status: "",
      loading: false,
      finished: false,
      refreshing: false,
      int: [
        {
          name: "天",
        },
        {
          name: "月",
        },
      ],
      for: {
        limit: 10,
        page: 0,
        type: 0,
      },
      intsindex: 0,
      muser: [],
      yunpai: [
        {
          name: "日期",
        },
        {
          name: "普通用户",
        },
        {
          name: "大仓会员",
        },
        {
          name: "商户",
        },
        {
          name: "渠道商",
        },
        {
          name: "代理商",
        },
      ],
      formdata: [],
    };
  },
  created() {
    this.Getdatatotals();
  },
  methods: {
    Getdatatotals() {
      Getdatatotal().then(
        (res) => {
          if (res.status == 200) {
            this.muser = res.data;
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
    onLoad() {
      if (this.status == 200) {
        this.loading = true;
        this.for.page++;
        this.Getdatalistls();
      } else {
        this.loading = false;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.for.page = 0;
      this.refreshing = true;
      this.onLoad();
    },
    Getdatalistls() {
      Getdatalistl(this.for).then(
        (res) => {
          this.refreshing = false;
          this.loading = false;
          this.status = res.status;
          if (res.status == 200) {
            if (res.data.list.length == 0) {
              this.finished = true;
            }
            if (this.for.page == 1) {
              this.formdata = res.data.list;
            } else {
              this.formdata = this.formdata.concat(res.data.list);
            }
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
    fanhui() {
      history.back();
    },
    ints(e) {
      this.intsindex = e;
      this.for.type = e;
      this.for.page = 1;
      this.Getdatalistls();
    },
  },
};
</script>
<style lang="scss" scoped>
.aboutlist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f8fc !important;
  .header {
    width: 100%;
    background-color: #fff;
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .neizi {
      width: 6.98rem;
      height: 0.88rem;
      display: flex;
      justify-content: space-between;
      .left {
        width: 2.06rem;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 0.36rem;
          height: 0.36rem;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .texts {
          font-size: 0.36rem;
          color: #333333;
          font-weight: bolder;
        }
      }
      .right {
        width: 1.46rem;
        height: 100%;
      }
    }
  }
  .user {
    width: 100%;
    height: 1.5rem;
    background-color: #fff;
    margin-top: 0.26rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .in {
      height: 0.7rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #333333;
      align-items: center;
      .top {
        font-size: 0.36rem;
      }
      .bottom {
        font-size: 0.26rem;
      }
    }
  }
  .tianshu {
    display: flex;
    .left {
      height: 0.3rem;
      margin: 0.32rem;
      display: flex;
      align-items: center;
      font-size: 0.3rem;
    }
  }
  .riqisi {
    width: 100%;
    height: 0.94rem;
    background: rgba(31, 124, 255, 0.1);
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.24rem;
    color: #333333;
    .int:nth-child(1) {
      padding-left: 0.5rem;
    }
    .int:nth-child(2) {
      padding-left: 0.6rem;
    }
  }
  .huakou {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .yi {
      width: 6.86rem;
      height: 1.14rem;
      border-bottom: 0.01rem solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.28rem;
      color: #333333;
    }
  }
}
</style>