import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/signin/Home.vue'
import About from '../views/homepage/About'
import Aboutlist from '../views/about/Aboutlist'
import Team from '../views/team/Team'
import Teamadd from '../views/teamadd/Teamadd'
import Dusers from '../views/dusers/Dusers'
import User from '../views/user/User'
import Agreement from '../views/agreement/Agreement'
import Channel from '../views/channel/Channel'
import { Message } from "element-ui";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      isShowTabBar: true,//显示
      requireAuth: true
    }
  },
  {
    path: '/aboutlist',
    name: 'Aboutlist',
    component: Aboutlist,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/team',
    name: 'Team ',
    component: Team,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/teamadd',
    name: 'Teamadd ',
    component: Teamadd,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/dusers',
    name: 'Dusers ',
    component: Dusers,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/user',
    name: 'User ',
    component: User,
    meta: {
      isShowTabBar: true,//显示
    }
  },
  {
    path: '/agreement',
    name: 'Agreement ',
    component: Agreement,
  },
  {
    path: '/channel',
    name: 'Channel ',
    component: Channel,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    var num = localStorage.getItem('token');
    if (num) {
      next();
    }
    else {
      Message({
        message: "请登录...",
        type: "error",
        duration: 2000,
      });
      next({
        path: '/',
      })
    }
  }
  else {
    next();
  }
}
)


export default router
