<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->

      <div class="tab-bar" v-if="$route.meta.isShowTabBar">
        <ul>
          <div class="clis">
            <!-- color: #1F7CFF; -->
            <div
              class="ho"
              :style="this.$route.path == '/about' ? 'color:#1F7CFF' : ''"
            >
              <div class="yisd" v-if="this.$route.path == '/about'">
                <img src="./assets/about/shouyehei.png" alt="" />
              </div>
              <div class="yisd" v-else>
                <img src="./assets/about/shouyesdf.png" alt="" />
              </div>
              <div>
                <router-link tag="li" to="/about">首页</router-link>
              </div>
            </div>

            <div
              class="ho"
              :style="this.$route.path == '/user' ? 'color:#1F7CFF' : ''"
            >
              <div class="yisd" v-if="this.$route.path != '/user'">
                <img src="./assets/about/wodehei.png" alt="" />
              </div>
              <div class="yisd" v-else>
                <img src="./assets/about/wodexuanzhong.png" alt="" />
              </div>
              <div>
                <router-link tag="li" to="/user">我的</router-link>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  created() {},
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}
.clis {
  position: fixed;
  width: 4.18rem;
  height: 0.66rem;
  bottom: 0.1rem;
  left: 1.66rem;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  font-size: 0.2rem;
}
.clis .ho {
  /* width: 0.44rem; */
  height: 0.66rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.2rem;
}
.clis .ho .yisd {
  width: 0.4rem;
  height: 0.44rem;
}
.clis .ho .yisd img {
  width: 100%;
  height: 100%;
  display: block;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
