<template>
  <div class="team">
    <div class="header">
      <div class="neizi">
        <div class="left">
          <div class="icon" @click="fanhui()">
            <img src="../../assets/signin/sinfh.png" alt="" />
          </div>
          <div class="texts">团队管理</div>
        </div>
        <div class="right"></div>
      </div>
    </div>
    <div class="neirong">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          offset="10"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="(item, index) in formdata" :key="index">
            <div>
              <div
                class="asdf"
                v-if="total > 0 ? true : false"
                @click="dianji(item.id)"
              >
                <div class="womk">
                  <div class="top">{{ item.name }}</div>
                  <div class="bottom">
                    <div class="lefts">
                      <div style="font-size: 0.32rem; color: #333333">
                        {{ item.nickname }}
                      </div>
                      <div style="font-size: 0.28rem; color: #999999">
                        {{ item.mobile }}
                      </div>
                    </div>
                    <div class="rights">
                      <img :src="item.avatar" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
      <div class="in" @click="Teamadd()" v-if="total >= 20 ? false : true">
        <div class="zhongts">
          <div class="left">
            <div class="texts">编辑</div>
            <div class="imkk">
              <img src="../../assets/team/add.png" alt="" />
            </div>
          </div>
          <div class="right">
            <img src="../../assets/team/fnahsij.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { Gitbindlist } from "../../api/teamlist";
export default {
  data() {
    return {
      for: {
        page: 0,
        limit: 10,
      },
      loading: false,
      finished: false,
      refreshing: false,
      formdata: [],
      total: "",
      list: [],
    };
  },
  created() {},
  methods: {
    fanhui() {
      history.back();
    },
    dianji(e) {
      this.$router.push({ path: "/dusers", query: { id: e } });
    },
    onLoad() {
      this.loading = true;
      this.for.page++;
      this.bindlist();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.for.page = 0;
      this.refreshing = true;
      this.onLoad();
    },
    bindlist() {
      Gitbindlist(this.for).then(
        (res) => {
          this.refreshing = false;
          this.loading = false;
          if (res.status == 200) {
            if (res.data.list.length == 0) {
              this.finished = true;
            }
            if (this.for.page == 1) {
              this.formdata = res.data.list;
            } else {
              this.formdata = this.formdata.concat(res.data.list);
            }
            this.total = res.data.total;
          } else if (res.status != 200) {
            Message({
              message: res.message,
              type: "error",
              duration: 2000,
            });
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
    Teamadd() {
      this.$router.push({ path: "/teamadd" });
    },
  },
};
</script>
<style lang="scss" scoped>
.team {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f8fc !important;
  ::v-deep .van-list {
    height: 800px;
    overflow-y: auto;
  }
  .header {
    width: 100%;
    background-color: #fff;
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .neizi {
      width: 6.98rem;
      height: 0.88rem;
      display: flex;
      justify-content: space-between;
      .left {
        width: 2.06rem;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 0.36rem;
          height: 0.36rem;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .texts {
          font-size: 0.36rem;
          color: #333333;
          font-weight: bolder;
        }
      }
      .right {
        width: 1.46rem;
        height: 100%;
      }
    }
  }
  .neirong {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .in {
      width: 6.86rem;
      height: 1.54rem;
      background-color: #fff;
      border-radius: 0.08rem;
      position: fixed;
      bottom: 0.38rem;
      // margin: 0.26rem 0 0.26rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .zhongts {
        width: 6.22rem;
        height: 0.9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          color: #333333;
          .texts {
            font-size: 0.3rem;
          }
          .imkk {
            margin-left: 0.16rem;
            width: 0.26rem;
            height: 0.26rem;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
        .right {
          width: 0.2rem;
          height: 0.2rem;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
      .zhongt {
        width: 6.22rem;
        height: 0.9rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 0.32rem;
          .left {
            font-size: 0.3rem;
            color: #333333;
          }
          .right {
            display: flex;
            align-items: center;
            .icon {
              width: 0.32rem;
              height: 0.32rem;
              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }
            .text {
              margin-left: 0.15rem;
              font-size: 0.3rem;
              color: #333333;
            }
            .insot {
              width: 0.2rem;
              height: 0.2rem;
              margin-left: 0.08rem;
              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          font-size: 0.28rem;
          color: #999999;
        }
      }
    }

    .asdf {
      width: 6.86rem;
      height: 2.45rem;
      background-color: #fff;
      border-radius: 0.08rem;
      margin: 0.26rem 0 0.26rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .womk {
        width: 6.22rem;
        height: 1.84rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
          width: 100%;
          height: 0.56rem;
          border-bottom: 0.01rem solid #e5e5e5;
          color: #333333;
          font-size: 0.3rem;
        }
        .bottom {
          width: 100%;
          height: 1.24rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .lefts {
            height: 0.76rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .rights {
            width: 0.98rem;
            height: 0.98rem;
            border-radius: 0.1rem;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 50%;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
::v-deep .van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #f7f8fc !important;
}
</style>