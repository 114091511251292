<template>
  <div class="dusers">
    <div class="header">
      <div class="neizi">
        <div class="left">
          <div class="icon" @click="fanhui()">
            <img src="../../assets/signin/sinfh.png" alt="" />
          </div>
          <div class="texts">普通用户</div>
        </div>
        <div class="right"></div>
      </div>
    </div>
    <div class="sliding_box">
      <div
        class="sliding_item"
        v-for="(item, index) in content"
        :key="index"
        @click="qiehuan(index, item.user_type)"
      >
        <div
          class="sliding_item_img"
          :style="is_xuan == index ? 'color:#1F7CFF' : 'color:#333333'"
        >
          {{ item.count }}
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="ribiao">
      <div class="yi">注册日期</div>
      <div class="yi">用户昵称</div>
      <div class="yi">电话</div>
    </div>
    <div class="huakou">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          offset="10"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="(item, index) in formdata" :key="index">
            <div class="yi">
              <div>{{ item.date }}</div>
              <div>{{ item.name }}</div>
              <div>{{ item.mobile }}</div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { Getdetaillis, Getdatatotal } from "../../api/aboutlist";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      is_xuan: "0",
      for: {
        ids: "",
        user_type: 0,
        page: 0,
        limit: 10,
      },
      formdata: [],
      content: [],
      status: "",
    };
  },
  created() {
    this.for.ids = this.$route.query;
    this.Getdatatotals();
  },
  methods: {
    Getdatatotals() {
      Getdatatotal(this.for.ids).then(
        (res) => {
          if (res.status == 200) {
            this.content = res.data;
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
    onLoad() {
      if (this.status != 400) {
        this.loading = true;
        this.for.page++;
        this.Getdetailliss();
      } else {
        this.loading = false;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.for.page = 0;
      this.refreshing = true;
      this.onLoad();
    },
    Getdetailliss() {
      Getdetaillis(this.for).then(
        (res) => {
          this.refreshing = false;
          this.loading = false;
          this.status = res.status;
          if (res.status == 200) {
            if (res.data.list.length == 0) {
              this.finished = true;
            }
            if (this.for.page == 1) {
              this.formdata = res.data.list;
            } else {
              this.formdata = this.formdata.concat(res.data.list);
            }
          } else if (res.status == 400) {
            Message({
              message: res.message,
              type: "error",
              duration: 2000,
            });
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
    fanhui() {
      history.back();
    },
    qiehuan(e, va) {
      this.is_xuan = e;
      this.for.user_type = va;
      this.for.page = 1;
      this.Getdetailliss();
    },
  },
};
</script>
<style lang="scss" scoped>
.dusers {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f8fc !important;
  .header {
    width: 100%;
    background-color: #fff;
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .neizi {
      width: 6.98rem;
      height: 0.88rem;
      display: flex;
      justify-content: space-between;
      .left {
        width: 2.06rem;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 0.36rem;
          height: 0.36rem;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .texts {
          font-size: 0.36rem;
          color: #333333;
          font-weight: bolder;
        }
      }
      .right {
        width: 1.46rem;
        height: 100%;
      }
    }
  }
  .sliding_box {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    .sliding_item {
      display: flex;
      margin: 0.26rem 0 0.5rem 0.5rem;
      .sliding_item_img {
        width: max-content;
        font-size: 0.3rem;
      }
    }
  }
  .sliding_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0; /*高宽分别对应横竖滚动条的尺寸*/
    height: 0;
  }

  .ribiao {
    width: 100%;
    height: 0.94rem;
    background: rgba(31, 124, 255, 0.1);
    display: flex;
    justify-content: space-around;
    align-content: center;
    color: #333333;
    font-size: 0.24rem;
    line-height: 0.94rem;
  }
  .huakou {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .yi {
      width: 6.86rem;
      height: 1.14rem;
      border-bottom: 0.01rem solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.28rem;
      color: #333333;
    }
  }
}
::v-deep .van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #f7f8fc !important;
}
</style>