<template>
  <div class="about">
    <!-- <div class="header">
      <div class="left">
        <div class="icon">
          <img src="../../assets/signin/sinfh.png" alt="" />
        </div>
        <div class="texts">手机登录</div>
      </div>
      <div class="right"></div>
    </div> -->
    <div class="in">
      <div class="top">
        <img src="../../assets/signin/sinicon.png" alt="" />
      </div>
      <div class="bottom">
        <img src="../../assets/signin/chuxubao.png" alt="" />
      </div>
    </div>
    <div class="btn">
      <div class="shang">
        <div class="input1 yzheng">
          <input
            type="text"
            v-model="fromdata.phone"
            placeholder="请输入手机号"
            maxlength="11"
            oninput="value=value.replace(/[^\d]/g,'')"
            autofocus
            pattern="[0-9]*"
          />
          <div class="fasong">
            <span
              v-show="show"
              @click="getCode"
              :style="
                fromdata.phone.length == 11 ? 'color:#1f7cff' : 'color:#5B5B5B'
              "
              >获取验证码</span
            >
            <!-- :style="show==false?'color:#1f7cff':'color:#5b5b5b'" -->
            <span v-show="!show" class="count" style="color: #9e9e9e"
              >重新获取{{ count }}S</span
            >
          </div>
        </div>
        <div class="input1">
          <input
            type="text"
            v-model="fromdata.code"
            placeholder="请输入验证码"
            maxlength="4"
            oninput="value=value.replace(/[^\d]/g,'')"
            autofocus
            pattern="[0-9]*"
          />
        </div>
      </div>
      <div class="zhong" @click="login">登录</div>
      <div class="xia">
        <div class="left">
          <img
            src="../../assets/signin/sinmeigou.png"
            v-if="is_wei"
            @click="gou"
            alt=""
          />
          <img
            v-if="is_yes"
            @click="gou"
            src="../../assets/signin/gouxuan.png"
            alt=""
          />
          <!-- <div class="xieyi">
            <img src="../../assets/signin/gouxuanxieyi.png" alt="" />
          </div> -->
        </div>
        <div class="right">
          登录代表您已同意<span style="color: #1f7cff" @click="xieyi()"
            >《注册协议》</span
          >和<span style="color: #1f7cff" @click="yincang()">《隐藏协议》</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { Postverify, Postlogin } from "../../api/request";
// import { Service } from '../../api/axios';
export default {
  data() {
    return {
      show: true,
      count: "",
      is_wei: true,
      is_yes: false,
      timer: null,
      fromdata: {
        phone: "",
        code: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.$router.push({ path: "/about" });
    } else {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    xieyi() {
      this.$router.push({ path: "/agreement", query: { type: "15" } });
    },
    yincang() {
      this.$router.push({ path: "/agreement", query: { type: "14" } });
    },
    gou() {
      if (this.is_wei == true) {
        this.is_wei = false;
        this.is_yes = true;
      } else {
        this.is_wei = true;
        this.is_yes = false;
      }
    },
    getCode() {
      var mobileReg = /^1[3,4,5,7,8,9][0-9]{9}$/;
      if (this.fromdata.phone == "") {
        Message({
          message: "请输入手机号",
          type: "error",
          duration: 2000,
        });
      } else if (!mobileReg.test(this.fromdata.phone)) {
        Message({
          message: "请输入合格手机号",
          type: "error",
          duration: 2000,
        });
      } else {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          if (this.show == false) {
            Postverify(this.fromdata).then(
              (res) => {
                if (res.status == 200) {
                  Message({
                    message: res.message,
                    type: "success",
                    duration: 2000,
                  });
                  return;
                } else if (res.status != 200) {
                  Message({
                    message: res.message,
                    type: "error",
                    duration: 2000,
                  });
                }
              },
              (fail) => {
                console.log(fail);
              }
            );
          }
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    login() {
      var mobileReg = /^1[3,4,5,7,8,9][0-9]{9}$/;
      if (this.fromdata.phone == "") {
        Message({
          message: "请输入手机号",
          type: "error",
          duration: 2000,
        });
      } else if (!mobileReg.test(this.fromdata.phone)) {
        Message({
          message: "请输入合格手机号",
          type: "error",
          duration: 2000,
        });
      } else if (this.fromdata.code == "") {
        Message({
          message: "请输入验证码",
          type: "error",
          duration: 2000,
        });
      } else if (this.is_wei == true) {
        Message({
          message: "请勾选协议",
          type: "error",
          duration: 2000,
        });
        return;
      } else {
        Postlogin(this.fromdata).then(
          (res) => {
            if (res.status == 400) {
              Message({
                message: res.message,
                type: "error",
                duration: 2000,
              });
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
              return;
            } else if (res.status != 400) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("userInfo", res.data.user);
              Message({
                message: res.message,
                type: "success",
                duration: 2000,
              });
              this.$router.push({ path: "/about" });
              return;
            }
          },
          (fail) => {
            console.log(fail);
          }
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.about {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 6.98rem;
    height: 0.88rem;
    display: flex;
    justify-content: space-between;
    .left {
      width: 2.06rem;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: 0.36rem;
        height: 0.36rem;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .texts {
        font-size: 0.36rem;
        color: #333333;
        font-weight: bolder;
      }
    }
    .right {
      width: 1.46rem;
      height: 100%;
    }
  }
  .in {
    width: 1.56rem;
    height: 2.11rem;
    // margin-top: 0.74rem;
    margin-top: 1.26rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .top {
      width: 1.37rem;
      height: 1.37rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom {
      width: 1.56rem;
      height: 0.48rem;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .btn {
    width: 6.58rem;
    height: 4.89rem;
    margin-top: 0.67rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .shang {
      width: 100%;
      height: 2.38rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .input1 {
      width: 100%;
      height: 1.19rem;
      border-bottom: 0.01rem #e5e5e5 solid;
      display: flex;
      align-items: center;
      input {
        width: 100%;
        height: 0.55rem;
        border: none;
        outline: none;
        margin: 0.52rem 0 0 0.12rem;
      }
      input::placeholder {
        color: #9e9e9e;
        font-size: 0.3rem;
        margin-bottom: 11px;
      }
    }
    .yzheng {
      position: relative;
      .fasong {
        width: 1.92rem;
        position: absolute;
        right: 0.3rem;
        top: 0.7rem;
        text-align: center;
        color: #5b5b5b;
        // width: 0.20rem;
        // height: 0.20rem;
        font-size: 0.3rem;
      }
    }
    .zhong {
      width: 6.58rem;
      height: 0.88rem;
      background-color: #1f7cff;
      border-radius: 0.1rem;
      text-align: center;
      line-height: 0.88rem;
      font-size: 0.3rem;
      opacity: 0.3;
    }
    .xia {
      width: 5.4rem;
      height: 0.33rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 0.28rem;
        height: 0.28rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
        .xieyi {
          position: absolute;
          width: 2.6rem;
          height: 0.69rem;
          bottom: 0.3rem;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
      .right {
        width: 5.05rem;
        height: 100%;
        font-size: 0.24rem;
      }
    }
  }
}
input::v-deep button,
input,
textarea {
  font: caption;
}
</style>

